<template>
  <div
    class="auth-wrapper auth-v2"
    style="background-image: url('/img/bg-login.png')"
  >
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-start p-2"
      >
        <!-- <div class="w-100 d-lg-flex align-items-start justify-content-center px-5">
          <b-img
            fluid
            :src="logoUrl"
          />
        </div> -->
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex right-col align-items-center auth-bg px-2 p-lg-5"
      >
        <!-- <div> -->
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto "
        >
          <b-img
            fluid
            :src="logoUrl"
            class="logo"
          />
          <!-- <b-card-title
            class="mb-1 font-weight-bold text-center"
            title-tag="h2"
          >
            ezGolf - Golf Management
          </b-card-title> -->
          <!-- <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text> -->
          <!--
          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">Admin:</span> admin@demo.com | admin</small>
              </p>
              <p>
                <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert> -->

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- username -->
              <b-form-group
                :label="$t('golf_common_username')"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('golf_common_username')"
                  rules="required"
                >
                  <b-form-input
                    v-model="userName"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t('golf_common_password') }}</label>
                  <!-- <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link> -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('golf_common_password')"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || isLoading"
              >
                {{ $t('golf_common_sigin') }}
                <b-spinner
                  v-if="isLoading"
                  small
                  label="Loading..."
                />
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
        <div class="copyright">
          <img :src="logoEz"> Powered by ezCloud</div>
      </b-col></b-row></div>
  <!-- </div> -->
  </b-col>
    <!-- /Login-->
  </b-row>

  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import { commonServices } from '@/api/common-services'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userName: '',
      // bgLogin: '/img/bgLogin.png',
      // logoUrl: require('@/assets/images/logo.png'),
      logoUrl: '/img/logo.png',
      logoEz: require('@/assets/images/logo-ez.svg'),
      // validation rules
      required,
      currentLang: 1000000, // this.$i18n.locale,
      hotelId: null,
      listCurrency: [],
      isLoading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    // Dịch vee validate
    if (this.currentLang == 1000000) {
      localize('vi')
    } else {
      localize('en')
    }
  },
  methods: {
    login() {
      this.isLoading = true
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          commonServices.login({
            UserName: this.userName,
            PassWord: this.password,
            // grant_type: 'password',
          })
            .then(response => {
              if (response.Status == 200) {
                if (response.Data.access_token) {
                  localStorage.setItem('foToken', JSON.stringify(response.Data.access_token))

                  commonServices
                    .getToken({
                      Token: response.Data.access_token,
                      Lang: 'vi',
                      UserName: this.userName,
                    })
                    .then(res => {
                      if (res.Status == '200') {
                        localStorage.setItem('userGolfData', JSON.stringify({
                          ...res.Data,
                        }))

                        commonServices
                          .switchSite({
                            SiteId: res.Data.Site[0].SiteId,
                          })
                          .then(res => {
                            if (res.Status == '200') {
                              localStorage.setItem('userGolfData', JSON.stringify({
                                ...res.Data,
                              }))

                              localStorage.setItem('currentSiteId', JSON.stringify(
                                res.Data.Site[0].SiteId,
                              ))

                              localStorage.setItem('timeZone', res.Data.Site[0].TimeZone)

                              localStorage.setItem('timeZoneOffset', res.Data.Site[0].TimeZoneOffset)

                              this.hotelId = {
                                hotelId: res.Data.Site[0].HotelId,
                              }

                              // Lấy cấu hình số lượng hố theo số hố thực

                              commonServices
                                .getCommon({ KeyGroup: 'BOOKING_POLICY' })
                                .then(res => {
                                  if (res.Status == '200') {
                                    if (res.Data.filter(x => x.KeyCode == 'NUMBER_OF_HOLE_4_CADDY').length > 0) {
                                      localStorage.setItem('numberOfHole4Caddy', res.Data.find(x => x.KeyCode == 'NUMBER_OF_HOLE_4_CADDY').KeyValue)
                                    } else {
                                      localStorage.setItem('numberOfHole4Caddy', '')
                                    }
                                  }
                                })


                              commonServices
                              .getCommon({ KeyGroup: 'MODULE' })
                              .then(res => {
                                if (res.Status == '200') {
                                  localStorage.setItem('module', JSON.stringify(res.Data.filter(x => x.KeyValue == 'ON').map(y => y.KeyCode)))
                                }
                              })

                              commonServices
                                .getCommon({ KeyGroup: 'BOOKING_CONFIG' })
                                .then(res => {
                                  if (res.Status == '200') {
                                    if (res.Data.filter(x => x.KeyCode == 'BOOKING_CADDY_CHARGE_FEE_DEFAULT').length > 0) {
                                      localStorage.setItem('isDefaultCaddyChargeFee', res.Data.find(x => x.KeyCode == 'BOOKING_CADDY_CHARGE_FEE_DEFAULT').KeyValue)
                                    } else {
                                      localStorage.setItem('isDefaultCaddyChargeFee', '')
                                    }

                                    if (res.Data.filter(x => x.KeyCode == 'STARTER_CREATE_GOLFER_GROUP').length > 0) {
                                      localStorage.setItem('isCreateGolferGroup', res.Data.find(x => x.KeyCode == 'STARTER_CREATE_GOLFER_GROUP').KeyValue)
                                    } else {
                                      localStorage.setItem('isCreateGolferGroup', '')
                                    }
                                  }
                                })
                              // Lấy danh sách tiền tệ và cấu hình
                              // Lấy hotel ID
                              const bodyCurrency = {
                                HotelId: res.Data.Site[0].HotelId,
                              }
                              commonServices
                                .getCurrency(bodyCurrency)
                                .then(res => {
                                  if (res.Status === '200') {
                                    this.listCurrency = {
                                      currency: res.Data.Currency,
                                    }

                                    const ability = {
                                      ability: [
                                        {
                                          action: 'manage',
                                          subject: 'all',
                                        },
                                      ],
                                    }

                                    localStorage.setItem('userData', JSON.stringify({
                                      ...this.hotelId,
                                      ...this.listCurrency,
                                      ...ability,
                                      ...{
                                        userName: response.Data.UserName,
                                        userId: response.Data.UserId,
                                      },
                                    }))
                                  } else {
                                    this.showResToast(res)
                                  }
                                })

                              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                              this.$router.replace(getHomeRouteForLoggedInUser('admin'))
                                .then(() => {
                                // this.$toast({
                                //   component: ToastificationContent,
                                //   position: 'top-right',
                                //   props: {
                                //     title: `Welcome ${userData.fullName || userData.username}`,
                                //     icon: 'CoffeeIcon',
                                //     variant: 'success',
                                //     text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                                //   },
                                // })
                                })
                            } else {
                              this.isLoading = false
                              this.showResToast(res)
                            }
                          })
                      } else {
                        this.isLoading = false
                        this.showResToast(res)
                      }
                    })

                  this.$ability.update(
                    [
                      {
                        action: 'manage',
                        subject: 'all',
                      },
                    ],
                  )
                }
              } else {
                this.isLoading = false
                this.showResToast(response)
              }
            })
            // .catch(error => {
            //   this.$refs.loginForm.setErrors(error.response.data.error)
            // })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.auth-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
}
.right-col {
  position: relative;
  .copyright{
    position: absolute;
    top: calc(100vh - 4rem);
    left: 12.2rem;
  }
}
.logo {
  min-width: 250px!important;
  max-width: 300px!important;
  display: block;
  margin: 0 auto;
}

</style>
